import React, { useState } from 'react'
import anime from 'animejs'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Location } from '@reach/router'
import ScrollLock from 'react-scrolllock'
import { makeStyles } from '@material-ui/styles'
import { Flipper, Flipped } from 'react-flip-toolkit'

import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import CloseIcon from '@material-ui/icons/Close'

import NavLink from '../atoms/NavLink'
import ExternalLink from '../atoms/ExternalLink'
import PageBackgroundImage from '../atoms/PageBackgroundImage'

import { trackEvent } from '../../utils/analytics'

import BluePrintImage2 from '../../assets/images/blueprint2-optimised.jpg'

import {
    TRANSITION_ALL,
    HOVER_OPACITY,
    EASE_OUT_QUINT,
    ZI_MAIN_NAV,
    OVERLAY_NAV_SPACING,
} from '../../constants/global'

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.dark,
        zIndex: ZI_MAIN_NAV,
        borderRadius: '50%',
        transition: `border-radius 0.25s ${EASE_OUT_QUINT} 0.35s`,
    },
    overlayClosed: {
        top: theme.spacing(2),
        right: theme.spacing(2),
        padding: 20,
        opacity: 0.75,
    },
    overlayOpen: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: 0,
        transition: `border-radius 0.1s ${EASE_OUT_QUINT} 0s`,
        opacity: 1,
    },
    overlayContent: {
        '& *[data-anim-fade-in], *[data-anim-slide-up]': {
            opacity: 0,
        },
    },
    navLink: {
        display: 'block',
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightLight,
        marginBottom: OVERLAY_NAV_SPACING,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(24),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(32),
        },
    },
    navSubLink: {
        display: 'block',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightLight,
        marginBottom: OVERLAY_NAV_SPACING,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(18),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(24),
        },
    },
    divider: {
        marginBottom: OVERLAY_NAV_SPACING,
        backgroundColor: theme.palette.text.primary,
        opacity: 0.2,
    },
    navLinkLast: {
        marginBottom: 0,
    },
    icon: {
        position: 'fixed',
        cursor: 'pointer',
        color: theme.palette.text.primary,
        transition: TRANSITION_ALL,
        zIndex: ZI_MAIN_NAV + 1,
        '&:hover': {
            opacity: HOVER_OPACITY,
        },
    },
    menuIcon: {
        top: 24,
        right: 24,
    },
    closeIcon: {
        top: theme.spacing(2),
        right: theme.spacing(2),
        fontSize: 34,
    },
    homeIcon: {
        top: theme.spacing(2),
        left: theme.spacing(2),
        fontSize: 34,
    },
    socialLinks: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2.6),
        zIndex: ZI_MAIN_NAV + 1,
    },
    socialLink: {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        lineHeight: 1,
        transition: TRANSITION_ALL,
        '&:hover': {
            opacity: HOVER_OPACITY,
        },
        '& svg': {
            height: '34px',
        },
    },
}))

function OverlayNav() {
    const classes = useStyles()

    const [isOpen, setIsOpen] = useState(false)

    const openMenu = () => {
        if (window) {
            trackEvent(
                'OverlayNav',
                'Open Nav',
                `Page: ${window.location.pathname}`
            )
        }

        setIsOpen(true)
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    const handleHomeIconClick = (location) => {
        if (location.pathname === '/') {
            closeMenu()
        } else {
            navigate('/')
        }
    }

    const animateContentIn = (el) => {
        // fade in
        const fadeAnim = anime({
            targets: el.querySelectorAll('*[data-anim-fade-in]'),
            opacity: [0, 1],
            duration: 2000,
            delay: 500,
            ease: 'easeOut',
            autoplay: false,
        })

        // slide up
        const slideAnim = anime({
            targets: el.querySelectorAll('*[data-anim-slide-up]'),
            translateY: [10, 0],
            opacity: [0, 1],
            duration: 1000,
            ease: 'easeOut',
            delay: (d, i) => i * 35,
            autoplay: false,
        })

        setTimeout(() => {
            fadeAnim.play()
            slideAnim.play()
        }, 300)
    }

    return (
        <Location>
            {({ location }) => {
                return (
                    <>
                        <ScrollLock isActive={isOpen} />
                        <Flipper flipKey={isOpen}>
                            {isOpen ? null : (
                                <MenuIcon
                                    onClick={openMenu}
                                    className={classnames(
                                        classes.icon,
                                        classes.menuIcon
                                    )}
                                />
                            )}
                            <Flipped
                                flipId="overlay"
                                onStart={animateContentIn}
                            >
                                <div
                                    className={classnames(
                                        classes.overlay,
                                        isOpen
                                            ? classes.overlayOpen
                                            : classes.overlayClosed
                                    )}
                                >
                                    {isOpen && (
                                        <Flipped flipId="overlayContent">
                                            <nav
                                                className={
                                                    classes.overlayContent
                                                }
                                            >
                                                <PageBackgroundImage
                                                    image={BluePrintImage2}
                                                />
                                                <div data-anim-fade-in>
                                                    <CloseIcon
                                                        onClick={closeMenu}
                                                        className={classnames(
                                                            classes.icon,
                                                            classes.closeIcon
                                                        )}
                                                    />
                                                </div>
                                                <div data-anim-fade-in>
                                                    <HomeIcon
                                                        onClick={() =>
                                                            handleHomeIconClick(
                                                                location
                                                            )
                                                        }
                                                        className={classnames(
                                                            classes.icon,
                                                            classes.homeIcon
                                                        )}
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={
                                                            classes.navLink
                                                        }
                                                        to={'/build/'}
                                                        label={
                                                            'New Build Homes'
                                                        }
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={
                                                            classes.navLink
                                                        }
                                                        to={'/extend/'}
                                                        label={
                                                            'Home Extentions'
                                                        }
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={
                                                            classes.navLink
                                                        }
                                                        to={'/renovate/'}
                                                        label={'Renovations'}
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={classnames(
                                                            classes.navLink
                                                        )}
                                                        to={'/contact/'}
                                                        label={'Contact'}
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <Divider
                                                        className={
                                                            classes.divider
                                                        }
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={
                                                            classes.navSubLink
                                                        }
                                                        to={'/why-choose-us/'}
                                                        label={'Why Choose Us'}
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={
                                                            classes.navSubLink
                                                        }
                                                        to={
                                                            '/building-process/'
                                                        }
                                                        label={
                                                            'Our Building Process'
                                                        }
                                                    />
                                                </div>
                                                <div data-anim-slide-up>
                                                    <NavLink
                                                        onActiveClick={
                                                            closeMenu
                                                        }
                                                        className={classnames(
                                                            classes.navSubLink,
                                                            classes.navLinkLast
                                                        )}
                                                        to={'/testimonials/'}
                                                        label={'Testimonials'}
                                                    />
                                                </div>
                                                <div
                                                    data-anim-fade-in
                                                    className={
                                                        classes.socialLinks
                                                    }
                                                >
                                                    <ExternalLink
                                                        className={
                                                            classes.socialLink
                                                        }
                                                        link="https://www.facebook.com/finelinesconstructionnz/"
                                                        gaEventLabel={
                                                            'OverlayNav: https://www.facebook.com/finelinesconstructionnz/'
                                                        }
                                                        title="Fine Lines Construction Facebook"
                                                    >
                                                        <svg
                                                            viewBox="0 0 50 50"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                fillRule="evenodd"
                                                                d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25zm0-3c12.15 0 22-9.85 22-22S37.15 3 25 3 3 12.85 3 25s9.85 22 22 22zm1.815-11V25h3.254l.43-3.792h-3.685l.005-1.898c0-.99.1-1.518 1.623-1.518h2.034V14h-3.255c-3.91 0-5.285 1.84-5.285 4.932v2.276H19.5v3.79h2.437V36h4.878zm0 0"
                                                            />
                                                        </svg>
                                                    </ExternalLink>
                                                    <ExternalLink
                                                        className={
                                                            classes.socialLink
                                                        }
                                                        link="https://www.instagram.com/finelinesconstructionnz/"
                                                        gaEventLabel={
                                                            'OverlayNav: https://www.instagram.com/finelinesconstructionnz/'
                                                        }
                                                        title="Fine Lines Construction Instagram"
                                                    >
                                                        <svg
                                                            viewBox="0 0 50 50"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                fillRule="evenodd"
                                                                d="M25 0C11.193 0 0 11.193 0 25s11.193 25 25 25 25-11.193 25-25S38.807 0 25 0zm0 3C12.85 3 3 12.85 3 25s9.85 22 22 22 22-9.85 22-22S37.15 3 25 3zm10.95 31.51c.02-.102.034-.206.05-.31V15.8c-.015-.102-.03-.203-.047-.304-.193-1.03-1.006-1.83-2.005-1.973-.04-.006-.082-.015-.123-.023h-17.65c-.107.02-.214.035-.32.06-.974.232-1.695 1.053-1.834 2.085-.004.043-.013.086-.02.13v18.452c.02.117.036.235.06.35.217.997 1.03 1.77 1.998 1.9.047.006.094.015.14.023h17.603c.116-.02.23-.037.343-.063.9-.2 1.633-.983 1.808-1.927zM16.38 33.1c0 .49.406.91.874.91h15.488c.473 0 .876-.42.876-.914v-10.27h-2.1c.296.99.388 2 .27 3.026-.116 1.028-.432 1.984-.948 2.867s-1.185 1.616-2.004 2.198c-2.124 1.512-4.92 1.645-7.172.323-1.14-.666-2.034-1.597-2.657-2.797-.93-1.79-1.08-3.67-.533-5.618H16.38V33.1zm8.518-3.442c2.49.056 4.505-2.025 4.558-4.555.054-2.61-1.96-4.765-4.456-4.764-2.44-.002-4.423 2.05-4.458 4.59-.035 2.607 1.962 4.674 4.356 4.728zm8.72-11.176v-2.18c0-.52-.394-.934-.892-.934h-2.078c-.496 0-.892.416-.892.936 0 .72-.002 1.44.002 2.16 0 .108.02.22.053.32.125.384.46.62.872.62.335.002.67 0 1.003 0 .362-.003.725.004 1.088-.005.465-.013.845-.43.845-.918zm0 0"
                                                            />
                                                        </svg>
                                                    </ExternalLink>
                                                </div>
                                            </nav>
                                        </Flipped>
                                    )}
                                </div>
                            </Flipped>
                        </Flipper>
                    </>
                )
            }}
        </Location>
    )
}

OverlayNav.propTypes = {
    location: PropTypes.object,
}

export default OverlayNav
