import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { StaticQuery, graphql } from 'gatsby'

import Footer from './organisms/Footer'
import OverlayNav from './molecules/OverlayNav'
import OverlayLogoLink from './molecules/OverlayLogoLink'
import PageBackgroundImage from '../components/atoms/PageBackgroundImage'

// TODO
// - fallback for animated homepage logo required in IE edge

const Layout = ({
    children,
    disableFooter,
    disableOverlayNav,
    disableOverlayLogoLink,
}) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                        description
                        keywords
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                        {
                            name: 'description',
                            content: `${data.site.siteMetadata.description}`,
                        },
                        {
                            name: 'keywords',
                            content: `${data.site.siteMetadata.keywords}`,
                        },
                    ]}
                />
                <PageBackgroundImage />
                <main>
                    {disableOverlayLogoLink ? null : <OverlayLogoLink />}
                    {disableOverlayNav ? null : <OverlayNav />}
                    {children}
                    {disableFooter ? null : <Footer />}
                </main>
            </>
        )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    disableFooter: PropTypes.bool,
    disableOverlayNav: PropTypes.bool,
    disableOverlayLogoLink: PropTypes.bool,
}

export default Layout
