import React from 'react'
import { makeStyles } from '@material-ui/styles'

import ExternalLink from './ExternalLink'

import '../../styles/animation.css'

const ANIM_SPEED = '0.3s'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '90px',
    margin: `${theme.spacing(6)}px auto 0 auto`,
  },
  sig: {
    width: '100%',
    height: 'auto',
    fill: '#fff',
    '& path': {
      opacity: 0.1,
      transformOrigin: 'center',
    },
    '&:hover': {
      '& #left-bracket': {
        animation: `fade-in-grow ${ANIM_SPEED} forwards`,
      },
      '& #mike-m': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.05s forwards`,
      },
      '& #mike-i': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.10s forwards`,
      },
      '& #mike-k': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.15s forwards`,
      },
      '& #mike-e': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.20s forwards`,
      },
      '& #francis-f': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.25s forwards`,
      },
      '& #francis-r': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.30s forwards`,
      },
      '& #francis-a': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.35s forwards`,
      },
      '& #francis-n': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.40s forwards`,
      },
      '& #francis-c': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.45s forwards`,
      },
      '& #francis-i': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.50s forwards`,
      },
      '& #francis-s': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.55s forwards`,
      },
      '& #right-bracket': {
        animation: `fade-in-grow ${ANIM_SPEED} 0.60s forwards`,
      },
    },
  },
}))

function MikeFrancisSig() {
  const classes = useStyles()

  return (
    <ExternalLink
      className={classes.root}
      link="http://www.mikefrancis.co.nz/"
      gaEventLabel={'Mike Francis - Web Developer'}
      gaEventValue={'http://www.mikefrancis.co.nz/'}
      title="Mike Francis - Web Developer"
    >
      <svg
        className={classes.sig}
        xmlns="http://www.w3.org/2000/svg"
        width="800"
        height="157.458"
        viewBox="-50 0 900 157.458"
      >
        <path
          id="right-bracket"
          d="M796.188 81.838c-2.745 6.004-7.022 12.46-12.807 19.377l-16.2 18.005-6.988 8.898c-3.037 3.312-6.434 4.83-10.166 4.546-2.05-.14-3.94-.97-5.674-2.486-1.728-1.52-2.588-3.088-2.588-4.712 0-4.165 5.19-11.612 15.563-22.345 12.148-12.42 19.1-20.22 20.862-23.398-11.44-7.557-23.156-17.968-35.153-31.24-2.122-2.33-2.826-6.528-2.122-12.6.28-2.325 1.452-3.494 3.488-3.494 1.276.07 2.862.6 4.766 1.592 8.054 4.373 22.414 16.02 43.1 34.94 3.037 2.753 4.555 6.073 4.555 9.956 0 1.13-.208 2.115-.637 2.962z"
        />
        <path
          id="francis-s"
          d="M688.742 46.547c0-7.38 1.412-13.86 4.255-19.436 3.186-6.437 7.76-11.05 13.733-13.837 2.715-1.308 5.385-1.937 7.986-1.88 4.977 0 8.715 1.307 11.204 3.912 1.162 1.276 1.773 2.52 1.828 3.74 0 2.272-1.688 4.267-5.074 5.988-.9.47-1.885.53-2.958.174-1.635-.47-2.48-.74-2.534-.795-.696 0-1.418.38-2.17 1.15-6.418 6.55-9.627 14.517-9.627 23.91 0 3.48.553 6.755 1.64 9.824.462 1.363 4.94 10.718 13.465 28.064 5.517 11.63 8.27 21.49 8.27 29.58 0 6.67-1.623 13.074-4.864 19.208-1.07 2.07-3.81 4.728-8.24 7.973-3.48 2.54-6.604 3.81-9.387 3.81-1.058 0-2.097-.183-3.1-.535-3.6-1.593-5.4-3.6-5.4-6.018 0-1.48.934-3.218 2.817-5.23.49-.586 1.715-.94 3.654-1.058 1.934-.122 3.224-.862 3.85-2.215 2.682-5.314 4.03-10.745 4.03-16.292 0-7.85-3.895-19.246-11.687-34.174-7.79-14.936-11.69-26.892-11.69-35.863z"
        />
        <path
          id="francis-i"
          d="M662.883 115.365c0-7.13.732-17.775 2.192-31.953 1.464-14.17 2.195-24.8 2.195-31.875 0-2.714-.1-5.38-.315-7.998 1.146-2 3.138-2.995 6.004-2.995 2.18 0 4.36.587 6.54 1.764.402-.31.743-.486.997-.537.303 0 .453.536.453 1.614-.103 1.28-.103 2.023 0 2.232.11.31.265.51.467.61.2 3.026.313 6.18.313 9.463 0 7.124-.734 17.878-2.192 32.258-1.463 14.38-2.195 25.235-2.195 32.566 0 2.718.05 5.41.15 8.076-1.03 2.818-3.244 4.23-6.634 4.23h-.297c-4.283-.21-6.646-2.146-7.057-5.807-.42-3.668-.623-7.552-.623-11.65z"
        />
        <path
          id="francis-c"
          d="M605.296 83.46c0-13.74 6.527-25.43 19.6-35.072 7.618-5.58 15.54-8.392 23.783-8.43 3.523 0 6.753.653 9.654 1.953.863.374 1.305 1.212 1.305 2.518 0 2.044-2.264 3.344-6.787 3.905-5.934.686-9.623 1.502-11.05 2.452-14.18 9.205-21.275 21.07-21.275 35.603 0 6.85 1.77 13.31 5.295 19.398 2.913 4.984 5.02 8.252 6.328 9.812 3.154 3.804 6.99 6.544 11.506 8.22 1.926.53 3.84 1.022 5.767 1.48 2.04.684 3.067 1.675 3.067 2.967 0 1.253-.684 2.376-2.05 3.37-1.05.832-2.2 1.252-3.43 1.252-.258 0-.53-.04-.835-.113-1.68-.156-4.222-.647-7.62-1.48-6.072-2.167-12.672-6.798-19.792-13.895l-5.39-5.524c-1.853-1.897-3.22-3.703-4.083-5.407-.624-1.25-1.702-6.28-3.25-15.09-.5-2.698-.744-5.337-.744-7.92z"
        />
        <path
          id="francis-n"
          d="M542.43 109.99c0-9.207.65-20.7 1.934-34.485l3.343-34.925c.105-1.91 2.036-2.867 5.812-2.867h.394c1.622 0 3.896 1.08 6.812 3.245 3.063 5.29 6.824 13.897 11.283 25.8 4.265 11.36 8.16 19.978 11.692 25.866l6.97-52.358c.298-1.697 1.792-2.553 4.48-2.553.908 0 1.99.13 3.263.38 1.668.34 2.65.556 2.964.637 1.005.343 1.69.954 2.037 1.85l-4.077 35.05c-1.657 13.912-2.463 25.513-2.418 34.8 0 1.065.048 2.622.14 4.678.094 2.056.14 3.594.14 4.614 0 1.267-.56 2.374-1.706 3.31-1.224 1.054-2.604 1.59-4.12 1.59-2.99 0-5.525-1.828-7.618-5.475-6.213-10.86-13.922-27.885-23.127-51.086-.186-.512-.442-.76-.78-.76-.667 0-1.087.59-1.273 1.776-1.672 12.428-2.5 25.513-2.5 39.258 0 2.202.107 5.598.297 10.177.19 4.58.288 8.04.288 10.372 0 2.292-.876 3.9-2.626 4.834-.723.595-1.52.908-2.396.957-.722 0-1.677-.278-2.862-.833-1.602-.59-2.602-.97-3.02-1.144-1.395-.335-2.245-1.375-2.548-3.117l-.32-4.327c-.307-4.873-.46-9.958-.46-15.263z"
        />
        <path
          id="francis-a"
          d="M471.77 132.842c-.06-.426-.09-.906-.09-1.446 0-4.017 1.3-9.132 3.908-15.354 3.165-8.024 4.928-13.727 5.284-17.1.103-.96-.612-2.058-2.17-3.288-1.56-1.23-2.347-2.172-2.347-2.81 0-.54.273-1.11.818-1.73.53-.61 1.76-1.112 3.66-1.485 1.91-.376 2.972-.963 3.198-1.763 1.948-5.497 5.02-15.745 9.217-30.734 3.922-13.023 7.315-23.012 10.155-29.953.646-1.563 2.02-2.345 4.12-2.345 1.556 0 2.916.51 4.105 1.536 3.062 2.642 5.053 6.597 5.968 11.876.645 4.518 1.255 9.043 1.85 13.566 5.478 26.004 11.21 47.448 17.164 64.33.645 1.77.977 3.3.977 4.596 0 2.2-.952 4.12-2.826 5.76-1.886 1.64-3.142 2.405-3.787 2.294-5.376-.963-8.78-5.287-10.232-12.973-1.56-8.385-3.395-15.37-5.478-20.964l-11.212.966c-4.29.543-7.656 1.858-10.073 3.96-1.386 3.342-3.17 9.315-5.347 17.91-1.883 8.09-3.716 14.242-5.505 18.443-.596 1.4-1.664 2.07-3.222 2.02-.7 0-1.48-.14-2.338-.406-3.66-1.186-5.585-2.82-5.795-4.908zm26.61-47.807c1.7-.317 6.21-.48 13.517-.48-.316-4.096-1.042-8.676-2.165-13.74-1.083-4.422-2.126-8.836-3.142-13.25-3.158 9.152-5.89 18.313-8.21 27.47z"
        />
        <path
          id="francis-r"
          d="M462.962 122.275c-12.382 9.944-25.77 14.916-40.157 14.916-14.868 0-26.317-5.09-34.35-15.273s-10.47-22.707-7.313-37.575c3.3-15.44 11.52-28.277 24.668-38.508 12.38-9.56 25.528-14.342 39.44-14.342 14.58 0 25.934 5.164 34.06 15.49 8.127 10.325 10.638 22.78 7.53 37.36-3.202 15.01-11.162 27.655-23.878 37.933zM409.467 53.72c-10.23 8.606-16.613 18.814-19.146 30.62-2.58 12.24-.5 22.637 6.24 31.194 6.692 8.558 16.062 12.836 28.11 12.836 11.998 0 23.16-4.303 33.487-12.908 10.326-8.652 16.78-19.026 19.36-31.122 2.486-11.76.43-21.967-6.166-30.62-6.692-8.795-16.038-13.194-28.037-13.194-12.143 0-23.425 4.4-33.846 13.195zm-4.876 60.02l12.478-58.585c3.633 0 9.06.012 16.278.036 7.22.025 11.234.062 12.047.108 4.59.335 8.223 1.34 10.9 3.012 4.637 2.868 6.262 7.53 4.876 13.983-1.054 4.924-3.192 8.486-6.42 10.684-3.226 2.2-6.872 3.514-10.935 3.944 3.488.766 6.023 1.89 7.602 3.372 2.82 2.772 3.608 7.147 2.365 13.122l-1.146 5.235c-.097.572-.18 1.146-.25 1.72-.073.574-.062 1.147.034 1.72l.144 1.65h-14.63c-.095-1.864.17-4.564.79-8.103.62-3.537.813-5.927.574-7.17-.335-2.056-1.46-3.49-3.37-4.303-1.053-.477-2.726-.79-5.02-.932l-3.37-.215h-3.228l-4.374 20.724H404.59zm37.935-47.04c-1.914-.812-4.78-1.22-8.606-1.22h-3.73l-3.585 16.997h5.95c3.587 0 6.527-.718 8.82-2.15 2.296-1.436 3.802-3.754 4.52-6.957.668-3.203-.455-5.426-3.37-6.67z"
        />
        <path
          id="francis-f"
          d="M412.286 2.392C410.584 1.21 408.613.625 406.37.625c-8.605.12-20.122 2.54-34.55 7.268-17.187 5.337-28.59 8.43-34.217 9.273-4.164.67-6.24 2.55-6.24 5.64 0 2.245 1.054 4.506 3.15 6.772 2.1 2.273 4.236 3.715 6.4 4.326.48 16.667.72 26.3.72 28.907 0 7.583-.06 14.397-.18 20.46-.54-.117-1.062-.18-1.544-.18-.032 0-.06.003-.087.005-.075.05-.156.09-.236.13-.18.085-.36.172-.537.264-.624.873-1.386 1.663-2.243 2.4-1.615 1.388-3.855 2.823-4.167 5-.26 1.854 1.047 3.318 2.332 4.565.845.447 1.885.93 3.117 1.453 2.242.974 3.305 2.273 3.18 3.907-.24 2.67-.357 5.82-.357 9.46v26.637c0 5.097.058 9.548.176 13.37.535 1.636 1.692 3.135 3.473 4.497 1.783 1.366 3.506 2.05 5.184 2.05 1.682.057 2.922-.61 3.728-2 3.62-6.25 5.438-14.7 5.438-25.373 0-2.973-.147-7.498-.452-13.593-.306-6.092-.453-10.62-.453-13.593 0-3.09.12-5.876.365-8.365 4.48-1.025 7.725-1.666 9.728-1.91L383.37 87c5.455-2.607 8.55-6.34 9.277-11.184.242-1.88-1.006-2.942-3.73-3.19-.543-.056-1.154-.09-1.815-.09-3.09 0-7.157.73-12.187 2.188-6.423 2-10.88 3-13.364 3-1.15 0-2.214-.124-3.178-.363-.492-8.12-.728-16.215-.728-24.277 0-8.062.235-15.998.728-23.82 3.09-.852 9.667-3.395 19.726-7.638 7.942-3.335 14.64-5.246 20.094-5.73 1.03-.12 2.413.215 4.14 1.002 1.724.79 3.075 1.058 4.04.818 2.49-.483 4.52-1.745 6.096-3.773 1.575-2.032 2.363-4.29 2.363-6.772-.002-2.005-.85-3.595-2.544-4.778z"
        />
        <path
          id="mike-e"
          d="M268.16 47.318l9.736-3.865c3.864-1.898 8.642-3.593 14.343-5.1 4.85-1.313 9.757-2.557 14.712-3.723.496-.094 1.117-.143 1.862-.143 2.723 0 5.918 1.048 9.58 3.134.105.39.348 1.13.744 2.226.396 1.095.597 1.886.597 2.37 0 .78-.348 1.436-1.042 1.968-3.768 1.507-9.302 3.256-16.61 5.252-7.31 1.99-12.845 3.713-16.61 5.174-.89 2.577-1.54 8.362-1.927 17.353 1.635.578 3.518.873 5.645.873 1.537 0 3.913-.12 7.134-.37 3.224-.25 5.603-.375 7.136-.375 2.825 0 5.25.598 7.286 1.788 1.432 2.03 2.152 3.366 2.152 4.013 0 .694-.35 1.237-1.037 1.632-.2.102-.92.817-2.156 2.16-.794.89-1.812 1.287-3.048 1.187-2.13.493-6.043.792-11.74.888-4.757.1-8.546.722-11.37 1.86-1.938 7.53-2.9 13.48-2.9 17.837 0 2.93.395 5.255 1.188 6.984 4.162 2.82 10.947 4.236 20.365 4.236 1.433 0 2.92-.044 4.456-.137.793 0 1.645-.362 2.567-1.086.913-.723 1.694-1.086 2.338-1.086.3-.044.592-.068.892-.068 2.33 0 3.89 2.15 4.682 6.467.3 1.635.024 3.15-.817 4.53-.842 1.39-2.08 2.135-3.717 2.23-1.83.048-3.615.074-5.347.074-10.06 0-18.93-.988-26.608-2.97-2.82-.692-5.413-2.265-7.765-4.723-2.354-2.45-3.682-5.186-3.976-8.208-.443-4.41-.67-8.6-.67-12.56 0-3.967.227-7.703.67-11.224-2.578-1.808-3.863-3.584-3.863-5.346 0-.215.024-.396.073-.555.097-.96.876-1.846 2.342-2.67 1.462-.83 2.303-1.648 2.52-2.468.23-.813.637-5.625 1.234-14.436-5.605-1.07-8.402-3.107-8.402-6.125 0-2.912 1.787-5.243 5.353-6.997z"
        />
        <path
          id="mike-k"
          d="M203.01 33.38c0-1.076.806-1.83 2.416-2.26l4.106-.638c1.452.43 2.943.95 4.476 1.57 1.532.614 2.35 1.572 2.457 2.86 1.45 13.215 2.177 24.017 2.177 32.4 0 1.13-.027 2.198-.08 3.22 4.488-4.08 9.67-9.452 15.524-16.12l14.67-17c1.34-.54 2.765-.805 4.27-.805 4.297 0 6.555 1.473 6.767 4.436-3.062 6.832-9.103 14.922-18.134 24.284-10.587 10.917-17.353 18.69-20.31 23.315l20.652 12.13c7.688 4.837 14.195 9.508 19.518 14.01.64.543.965 1.32.965 2.336 0 .647-.43 2.283-1.288 4.917-.43-.106-.833-.16-1.21-.16-.646 0-1.49.277-2.54.846-1.05.562-1.843.847-2.376.847-.534 0-1.155-.218-1.853-.647l-34.33-20.79c.624 4.08 1.203 9.908 1.723 17.49 0 .857.212 2.17.632 3.947.416 1.77.623 3.037.623 3.787 0 2.627-1.42 4.457-4.265 5.48-.795.428-1.953.647-3.474.647-1.34 0-2.17-.41-2.495-1.214-2.845-.963-4.488-2.873-4.917-5.717-1.34-7.903-2.014-17.198-2.014-27.886 0-3.87.118-9.632.363-17.29.245-7.66.362-13.395.362-17.207 0-4.192-.403-9.695-1.208-16.523-.804-6.825-1.207-11.577-1.207-14.265z"
        />
        <path
          id="mike-i"
          d="M176.575 111.824c0-6.578.766-16.412 2.296-29.496 1.534-13.086 2.3-22.9 2.3-29.425 0-2.512-.106-4.97-.323-7.39 1.2-1.84 3.293-2.77 6.295-2.77 2.28 0 4.56.552 6.842 1.64.436-.286.778-.455 1.05-.502.323 0 .48.504.48 1.495-.105 1.18-.105 1.87 0 2.055.114.286.272.48.49.573.21 2.79.315 5.705.315 8.727 0 6.58-.767 16.508-2.297 29.78-1.524 13.28-2.29 23.296-2.29 30.068 0 2.506.05 4.994.156 7.453-1.09 2.605-3.407 3.905-6.954 3.905h-.31c-4.495-.188-6.966-1.977-7.4-5.36-.432-3.38-.65-6.97-.65-10.75z"
        />
        <path
          id="mike-m"
          d="M64.22 139.048c0-1.208 1.438-5.615 4.335-13.21 1.21-3.296 6.01-19.197 14.42-47.696 2.886-9.887 5.912-27.3 9.073-52.24.268-.268.976-1.715 2.12-4.33.673-1.95 1.917-2.93 3.727-2.93 4.71 0 7.936.91 9.68 2.72 0 3.967.658 10.39 1.967 19.263 1.314 8.875 1.972 15.597 1.972 20.17 1.21 7.73 2.387 15.497 3.53 23.292 1.546 8.81 4.3 16.03 8.266 21.68 13.244-36.77 21.615-65.812 25.11-87.127.536-2.22 2.625-3.325 6.253-3.325 5.105 0 8.002 1.646 8.67 4.937.942 4.976 1.413 10.69 1.413 17.147 0 2.082-.134 6.654-.4 13.71-.07 2.824-.102 5.38-.102 7.666 0 2.288.03 4.336.1 6.154.402 9.876 1.867 23.037 4.384 39.472 2.525 16.442 3.784 27.92 3.784 34.44 0 2.22-2.017 3.462-6.054 3.732h-.504c-2.887 0-5.847-1.31-8.872-3.932-2.625-2.354-4.674-6.152-6.154-11.4-1.342-4.836-2.18-11.628-2.517-20.365-.27-9.884-.74-16.703-1.414-20.473-1.816 6.188-4.772 15.397-8.874 27.63-2.484 6.726-3.997 10.593-4.532 11.596-2.02 3.902-4.476 5.85-7.36 5.85-.273 0-.577-.033-.914-.102-6.59-1.546-11.695-5.24-15.323-11.094-2.354-3.824-4.54-9.98-6.556-18.45-2.22-9.21-4.07-15.393-5.55-18.556-5.916 28.772-12.268 50.257-19.06 64.435-.47.946-1.343 1.415-2.623 1.415-1.474 0-3.496-.607-6.047-1.816-3.967-1.95-5.947-4.703-5.947-8.264z"
        />
        <path
          id="left-bracket"
          d="M3.176 78.876c0-3.882 1.518-7.203 4.555-9.956C28.413 50 42.78 38.353 50.83 33.98c1.906-.994 3.495-1.522 4.764-1.592 2.05 0 3.216 1.168 3.492 3.495.708 6.07 0 10.27-2.113 12.598C44.97 61.755 33.25 72.167 21.81 79.724c1.77 3.177 8.724 10.977 20.864 23.4 10.38 10.732 15.564 18.18 15.564 22.344 0 1.624-.863 3.194-2.598 4.712-1.726 1.516-3.614 2.346-5.66 2.485-3.744.285-7.134-1.233-10.164-4.546l-6.992-8.9-16.198-18.004c-5.79-6.918-10.06-13.373-12.813-19.377-.423-.85-.637-1.834-.637-2.964z"
        />
      </svg>
    </ExternalLink>
  )
}

export default MikeFrancisSig
