import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import FooterNav from '../../components/molecules/FooterNav'
import MikeFrancisSig from '../../components/atoms/MikeFrancisSig'

import ExternalLink from '../atoms/ExternalLink'

import { HOVER_OPACITY } from '../../constants/global'

const useStyles = makeStyles((theme) => ({
    footer: {
        marginTop: theme.spacing(16),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        backgroundColor: theme.palette.primary.dark,

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(20),
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
        },
    },
    associationLinks: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        margin: `${theme.spacing(6)}px auto`,
    },
    associationLink: {
        margin: theme.spacing(3),
        width: '100%',
        maxWidth: '70px',

        '&.lbpLogo': {
            maxWidth: '160px',
        },

        '&.nzcbLogo': {
            maxWidth: '50px',
        },

        '&.bcitoLogo': {
            maxWidth: '100px',
        },

        '&.hazardcoLogo': {
            maxWidth: '150px',
        },

        '& .gatsby-image-wrapper': {
            width: '100%',
        },

        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(4),
            maxWidth: '90px',

            '&.lbpLogo': {
                maxWidth: '180px',
            },

            '&.nzcbLogo': {
                maxWidth: '60px',
            },

            '&.bcitoLogo': {
                maxWidth: '120px',
            },

            '&.hazardcoLogo': {
                maxWidth: '170px',
            },
        },
    },
    copyright: {
        textAlign: 'center',
        fontSize: '12px',
        marginTop: theme.spacing(6),
        opacity: HOVER_OPACITY,
    },
}))

const findImageByName = (name, images) => {
    const foundImage = images.find((image) => {
        return image.node.childImageSharp.fluid.originalName === name
    })

    return foundImage
}

function Footer({ data }) {
    const classes = useStyles()

    const images = data.allFile.edges
    const bcitoImgFluid = findImageByName('bw-bcito-logo.png', images).node
        .childImageSharp.fluid
    const cbscoopImgFluid = findImageByName('bw-cbscoop-logo.png', images).node
        .childImageSharp.fluid
    const houzzImgFluid = findImageByName('bw-houzz-logo.png', images).node
        .childImageSharp.fluid
    const haloImgFluid = findImageByName('bw-halo-logo.png', images).node
        .childImageSharp.fluid
    const lbpImgFluid = findImageByName('bw-lbp-logo.png', images).node
        .childImageSharp.fluid
    const nzcbImgFluid = findImageByName('bw-nzcb-logo.png', images).node
        .childImageSharp.fluid
    const hazardcoImgFluid = findImageByName('bw-hazardco-logo.png', images)
        .node.childImageSharp.fluid

    return (
        <footer className={classes.footer}>
            <FooterNav />
            <div className={classes.associationLinks}>
                <ExternalLink
                    className={classNames(classes.associationLink, 'lbpLogo')}
                    link="https://www.lbp.govt.nz/"
                    title="Fine Lines Construction - Registered Licensed Building Practitioners"
                    gaEventLabel={'Footer: https://www.lbp.govt.nz/'}
                >
                    <Img
                        fluid={lbpImgFluid}
                        alt={
                            'Fine Lines Construction - Registered Licensed Building Practitioners'
                        }
                    />
                </ExternalLink>
                <ExternalLink
                    className={classNames(classes.associationLink, 'nzcbLogo')}
                    link="https://www.nzcb.nz/"
                    title="Fine Lines Construction - Approved members of the New Zealand Certified Builders Association"
                    gaEventLabel={'Footer: https://www.nzcb.nz/'}
                >
                    <Img
                        fluid={nzcbImgFluid}
                        alt={
                            'Fine Lines Construction - Approved members of the New Zealand Certified Builders Association'
                        }
                    />
                </ExternalLink>
                <ExternalLink
                    className={classNames(classes.associationLink, 'haloLogo')}
                    link="https://www.nzcb.nz/guaranteed-peace-of-mind/"
                    title="Fine Lines Construction - Covered by Halo 10 year residential guarantee insurance"
                    gaEventLabel={
                        'Footer: https://www.nzcb.nz/guaranteed-peace-of-mind/'
                    }
                >
                    <Img
                        fluid={haloImgFluid}
                        alt={
                            'Fine Lines Construction - Covered by Halo 10 year residential guarantee insurance'
                        }
                    />
                </ExternalLink>
                <ExternalLink
                    className={classNames(classes.associationLink, 'bcitoLogo')}
                    link="https://bcito.org.nz/"
                    title="Fine Lines Construction - Offer BCITO building and construction apprenticeships"
                    gaEventLabel={'Footer: https://bcito.org.nz/'}
                >
                    <Img
                        fluid={bcitoImgFluid}
                        alt={
                            'Fine Lines Construction - Offer BCITO building and construction apprenticeships'
                        }
                    />
                </ExternalLink>
                <ExternalLink
                    className={classNames(
                        classes.associationLink,
                        'cbscoopLogo'
                    )}
                    link="https://www.cbscoop.co.nz/"
                    title="Fine Lines Construction - Members of the Combined Building Supplies Cooperative"
                    gaEventLabel={'Footer: https://www.cbscoop.co.nz/'}
                >
                    <Img
                        fluid={cbscoopImgFluid}
                        alt={
                            'Fine Lines Construction - Members of the Combined Building Supplies Cooperative'
                        }
                    />
                </ExternalLink>
                <ExternalLink
                    className={classNames(classes.associationLink, 'houzzLogo')}
                    link="https://www.houzz.co.nz/professionals/home-builders/fine-lines-construction-ltd-pfvwnz-pf~2016084183"
                    title="Fine Lines Construction - Houzz - Projects"
                    gaEventLabel={
                        'Footer: https://www.houzz.co.nz/professionals/home-builders/fine-lines-construction-ltd-pfvwnz-pf~2016084183'
                    }
                >
                    <Img
                        fluid={houzzImgFluid}
                        alt={'Fine Lines Construction - Houzz - Projects'}
                    />
                </ExternalLink>
                <ExternalLink
                    className={classNames(
                        classes.associationLink,
                        'hazardcoLogo'
                    )}
                    link="https://www.hazardco.com/"
                    title="Fine Lines Construction - Members of HazardCo Health and Safety"
                    gaEventLabel={'Footer: https://www.hazardco.com/'}
                >
                    <Img
                        fluid={hazardcoImgFluid}
                        alt={
                            'Fine Lines Construction - Members of HazardCo Health and Safety'
                        }
                    />
                </ExternalLink>
            </div>
            <Typography className={classes.copyright} variant="body2">
                &copy;
                {new Date().getFullYear()} Fine Lines Construction
            </Typography>
            <MikeFrancisSig />
        </footer>
    )
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allFile(
                    filter: {
                        sourceInstanceName: { eq: "images" }
                        relativeDirectory: { eq: "flc-partner-logos" }
                    }
                ) {
                    edges {
                        node {
                            id
                            childImageSharp {
                                fluid {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    originalImg
                                    originalName
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <Footer data={data} {...props} />}
    />
)
