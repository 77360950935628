import React from 'react'
import PropTypes from 'prop-types'

import BluePrintImage3 from '../../assets/images/blueprint3-optimised.jpg'

const PageBackgroundImage = ({ image }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url('${image}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: 0.125,
        zIndex: -1,
        pointerEvents: 'none',
      }}
    />
  )
}

PageBackgroundImage.defaultProps = {
  image: BluePrintImage3,
}

PageBackgroundImage.propTypes = {
  image: PropTypes.string,
}

export default PageBackgroundImage
