import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Location } from '@reach/router'
import { makeStyles } from '@material-ui/styles'

import { TRANSITION_ALL, HOVER_OPACITY } from '../../constants/global'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.button,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    transition: TRANSITION_ALL,
    '&:hover': {
      opacity: HOVER_OPACITY,
      cursor: 'pointer',
    },
  },
  isActive: {
    opacity: HOVER_OPACITY / 1.5,
    '&:hover': {
      opacity: HOVER_OPACITY / 1.5,
      cursor: 'default',
    },
  },
}))

function NavLink({
  className,
  activeClassName,
  to,
  label,
  onClick,
  onActiveClick,
}) {
  const classes = useStyles()

  const isActive = (location, to) => {
    return location.pathname === to
  }

  return (
    <Location>
      {({ location }) => {
        return (
          <Link
            to={to}
            className={classnames(classes.root, className)}
            activeClassName={activeClassName || classes.isActive}
            onClick={isActive(location, to) ? onActiveClick : onClick}
          >
            {label}
          </Link>
        )
      }}
    </Location>
  )
}

NavLink.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  onActiveClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default NavLink
