import theme from '../styles/theme'

// content
export const MAX_CONTENT_WIDTH = '1280px'
export const MAX_CONTENT_WIDTH_SML = '850px' // Used for small content block and header copy

// overlay
export const OVERLAY_OPACITY = 0.9

// overlay nav
export const OVERLAY_NAV_SPACING = theme.spacing(1.5)

// splashnav
export const SPLASHNAV_OVERLAY_OPACITY = 0.65
export const SPLASHNAV_OVERLAY_OPACITY_ACTIVE = 0.35

// transitions
export const FADE_DURATION = 1000
export const TRANSITION_DURATION = '0.3s'
export const TRANSITION_ALL = 'all 0.3s ease-in-out'
export const TRANSITION_EASE = 'ease-in-out'
export const EASE_IN_QUINT = 'cubic-bezier(0.755, 0.05, 0.855, 0.06)'
export const EASE_OUT_QUINT = 'cubic-bezier(0.23, 1, 0.32, 1)'
export const EASE_IN_OUT_QUINT = 'cubic-bezier(0.86, 0, 0.07, 1)'

// font
export const TEXT_SHADOW = '0 1px 2px #133546'
export const HOVER_OPACITY = 0.7

// z-index
export const ZI_MAIN_NAV = 100
export const ZI_GALLERY = 110
