import React from 'react'
import { navigate } from 'gatsby'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import SvgFlcLogoInline from '../atoms/SvgFlcLogoInline'
import SvgFlcIconOutline from '../atoms/SvgFlcIconOutline'

import { HOVER_OPACITY, TRANSITION_ALL } from '../../constants/global.js'

import { trackEvent } from '../../utils/analytics'

const useStyles = makeStyles(theme => ({
  logo: {
    width: '41px',
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    cursor: 'pointer',
    zIndex: 3,
    [theme.breakpoints.up('md')]: {
      width: '318px',
    },
  },
  logoIcon: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoInline: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  logoSVG: {
    transition: TRANSITION_ALL,
    '&:hover': {
      opacity: HOVER_OPACITY,
    },
  },
}))

function OverlayLogoLink() {
  const classes = useStyles()

  const handleOnClick = () => {
    if (window) {
      trackEvent(
        'OverlayLogoLink',
        'Click',
        `Page: ${window.location.pathname}`
      )
    }

    navigate('/')
  }

  return (
    <div className={classes.logo} onClick={handleOnClick} role="link">
      <SvgFlcIconOutline
        className={classnames(classes.logoSVG, classes.logoIcon)}
      />
      <SvgFlcLogoInline
        className={classnames(classes.logoSVG, classes.logoInline)}
      />
    </div>
  )
}

export default OverlayLogoLink
