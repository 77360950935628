import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'

import { TRANSITION_ALL, HOVER_OPACITY } from '../../constants/global'

import { trackEvent } from '../../utils/analytics'

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.button,
    display: 'flex',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    transition: TRANSITION_ALL,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '&:hover': {
      opacity: HOVER_OPACITY,
      cursor: 'pointer',
    },
  },
}))

function ExternalLink({
  className,
  link,
  title,
  gaEventLabel,
  gaEventValue,
  children,
}) {
  const classes = useStyles()

  const handleOnClick = () => {
    if (window) {
      const gaEventTrackingLabel =
        gaEventLabel || `Page: ${window.location.pathname} - Link: ${link}`

      trackEvent('ExternalLink', 'Click', gaEventTrackingLabel, gaEventValue)
    }
  }

  return (
    <a
      className={classnames(classes.root, className ? className : null)}
      href={link}
      onClick={handleOnClick}
      target="_blank"
      rel="noopener noreferrer"
      title={title || null}
    >
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gaEventLabel: PropTypes.string,
  gaEventValue: PropTypes.any,
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
}

export default ExternalLink
